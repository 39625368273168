import { definitionsFromContext } from "stimulus/webpack-helpers";
import { Application } from "stimulus";
import editorialContentController from "../../../controllers_v2/editorial_content_controller";
import highlightsController from "../../../controllers_v2/highlights_controller";
import coinTableController from "../../../controllers_v2/coin_table_controller";
import portfolioTransactionValuesController from "../../../controllers_v2/portfolios/portfolio_transaction_values_controller";
import portfolioTransactionsModalController from "../../../controllers_v2/portfolios/ajax/portfolio_transactions_modal_controller";

const application = Application.start();
const context = require.context("controllers_v2/coins", true, /.js$/);

application.load(definitionsFromContext(context));
application.register("editorial-content", editorialContentController);
application.register("highlights", highlightsController);
application.register("coin-table", coinTableController);
application.register("portfolio-transaction-values", portfolioTransactionValuesController);
application.register("portfolio-transactions-modal", portfolioTransactionsModalController);
