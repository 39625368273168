import { Controller } from "stimulus";
import { getCSRFToken } from "../../util";

export default class extends Controller {
  static targets = ["button"];

  vote(e) {
    if (this.buttonTarget.disabled) {
      return;
    }

    let totalVotesSpan = this.buttonTarget.querySelector("span.coin-insight-total-votes");
    if (totalVotesSpan === null) {
      return;
    }

    let totalVotes = Number(totalVotesSpan.innerHTML);
    if (isNaN(totalVotes)) {
      return;
    }

    const coinInsightId = this.buttonTarget.dataset.coinInsightId;

    totalVotesSpan.innerHTML = totalVotes + 1;
    this.buttonTarget.disabled = true;

    this._sendVote(coinInsightId);
  }

  _sendVote(coinInsightId) {
    fetch(`/coin_insights/${coinInsightId}/votes`, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken(),
      },
      body: "",
    });
  }
}
