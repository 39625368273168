import { Controller } from "stimulus";
import { EventGeckoChartRendered } from "../../events";
import { createPopper } from "@popperjs/core";
import { useClickOutside } from "stimulus-use";
import { renderTradingViewChart } from "../../template/charts/tradingview";
import { loadFlatpickr } from "../../util/load_package";
import { getActiveCurrency } from "../../util/currency";

export default class extends Controller {
  static targets = [
    "chart", "rangeSelector", "chartTypeSelector",
    "lineButton", "ohlcButton", "calendarButton",
    "btcCheckbox", "ethCheckbox",
    "exportButton", "exportDropdownMenu",
    "normalChart", "liveChartButton",
    "tradingViewChartContainer", "tradingViewChart",
  ];

  chartInstance = null;
  chartUrls = null;

  selectedChart = "price";
  selectedRange = "h24";
  isLogEnabled = false;
  isBtcEnabled = false;
  isEthEnabled = false;

  calendarInstance = null;

  connect() {
    useClickOutside(this, { element: this.exportButtonTarget });
    window.addEventListener(EventGeckoChartRendered, this.onChartRerender.bind(this));

    this.chartUrls = JSON.parse(this.element.dataset.chartUrls);

    // Has "Live Chart" button; query API to see if we should surface the button.
    if (this.hasLiveChartButtonTarget) {
      const address = this.liveChartButtonTarget.getAttribute("data-address");
      const network = this.liveChartButtonTarget.getAttribute("data-network");

      fetch(`/geckoterminal/token_is_valid?token=${address}&network=${network}`)
        .then(response => response.json())
        .then(res => {
          if (res === true) {
            this.liveChartButtonTarget.classList.remove("!tw-hidden");
          }
        });
    }
  }

  handleOptionChange(e) {
    const optionType = e.currentTarget.dataset.option;
    const optionKey = e.currentTarget.dataset.key;

    if (!["selectedChart", "selectedRange"].includes(optionType)) {
      console.error(`Unknown option type '${optionType}', valid types are 'selectedChart' and 'selectedRange'.`);
      return;
    }

    if (this[optionType] === optionKey) {
      return;
    }

    // Remove all selected to prevent double selection.
    if (optionType === "selectedRange") {
      this.rangeSelectorTarget.querySelectorAll(".selected").forEach((x) => { x.classList.remove("selected") });
    }

    // Too many edge cases for custom range, reset to 24h when changing chart type.
    if (this.selectedRange === "custom" && optionType === "selectedChart") {
      this.selectedRange = "h24";
      this.rangeSelectorTarget?.querySelector("[data-key='h24']")?.classList?.add("selected");
    }

    e.currentTarget.classList.add("selected");

    this[optionType] = optionKey;
    this.refreshChartSettings();
  }


  handleScaleChange(e) {
    e.currentTarget.classList.toggle("selected");

    this.isLogEnabled = !this.isLogEnabled;
    const type = this.isLogEnabled ? "logarithmic" : "linear";

    this.chartTarget.dataset.scaleType = type;
    this.chartInstance.yAxis.forEach(yAxis => yAxis.update({ type }));
  }


  handleChartTypeChange(e) {
    // Market cap only supports line chart.
    if (this.selectedChart === "market_cap") {
      return;
    }

    // OHLC doesn't support custom ranges, reset to 24h.
    if (this.selectedRange === "custom") {
      this.selectedRange = "h24";
      this.rangeSelectorTarget?.querySelector("[data-key='h24']")?.classList?.add("selected");
    }

    this.selectedChart = e.currentTarget.id === "chart-type-ohlc" ? "ohlc" : "price";
    this.refreshChartSettings();
  }


  handleFullscreen() {
    this.chartInstance.fullscreen.toggle();
  }


  handleBtcToggle(e) {
    e.preventDefault();

    this.isBtcEnabled = !this.isBtcEnabled;
    this.btcCheckboxTarget.checked = this.isBtcEnabled;
    this.refreshChartSettings(true);
  }

  handleEthToggle(e) {
    e.preventDefault();

    this.isEthEnabled = !this.isEthEnabled;
    this.ethCheckboxTarget.checked = this.isEthEnabled;
    this.refreshChartSettings(true);
  }


  async handleChartExport(e) {
    let exportType, exportTitle;
    switch (e.currentTarget.dataset.type) {
      case "png":
        exportType = "image/png";
        break;
      case "pdf":
        exportType = "application/pdf";
        break;
      case "svg":
        exportType = "image/svg+xml";
        break;
      default:
        exportType = "image/jpeg";
        break;
    }

    const coinName = this.element.dataset.coinName;
    if (coinName) {
      const chartLabel = this.selectedChart === "market_cap" ? "Market Cap" : "Price";
      exportTitle = `${coinName} ${chartLabel} Chart (${getActiveCurrency().toUpperCase()})`;
    }

    this.chartInstance.exportChart({
      url: "https://export.highcharts.com/",
      type: exportType,
      scale: 2,
      sourceWidth: this.chartInstance.chartWidth,
      sourceHeight: this.chartInstance.chartHeight,
    }, {
      title: {
        align: "left",
        text: exportTitle
      }
    });
  }

  showExportDropdown(e) {
    e.stopPropagation();

    if (this.exportDropdownPopperInstance) {
      return this.hideExportDropdown(e);
    }

    this.exportButtonTarget.classList.add("selected");
    this.exportDropdownMenuTarget.classList.remove("tw-hidden");
    this.exportDropdownPopperInstance = createPopper(this.exportButtonTarget, this.exportDropdownMenuTarget, { placement: "bottom-start" });
  }

  hideExportDropdown(e) {
    if (!this.exportDropdownPopperInstance) {
      return;
    }

    this.exportButtonTarget.classList.remove("selected");
    this.exportDropdownMenuTarget.classList.add("tw-hidden");
    this.exportDropdownPopperInstance?.destroy();
    this.exportDropdownPopperInstance = null;
  }


  handleTradingViewChart() {
    if (!this.hasTradingViewChartContainerTarget || !this.hasTradingViewChartTarget) {
      return;
    }

    this.tradingViewChartContainerTarget.classList.remove("tw-hidden");
    this.normalChartTarget.classList.add("tw-hidden");
    this.chartTypeSelectorTarget.classList.add("tw-hidden");
    this.rangeSelectorTarget.classList.add("tw-hidden");

    this.selectedChart = "tradingview";

    renderTradingViewChart(this.tradingViewChartTarget, this.tradingViewChartTarget.dataset.coinApiSymbol);
  }


  handleCalendarChange(selectedDates) {
    // Our Flatpickr is a range selector, so we need to check whether the returned array has two dates (to, from).
    if (!selectedDates || selectedDates.length !== 2) {
      return;
    }

    const [fromDate, toDate] = selectedDates;

    fromDate.setHours(0, 0, 0);
    toDate.setHours(23, 59, 59);

    this.chartInstance.xAxis[0].setExtremes(fromDate.getTime(), toDate.getTime(), true, false, { trigger: "calendar" });
  }

  toggleCalendar(e) {
    if (this.calendarOpen === true) {
      this.calendarInstance.close();
      this.calendarOpen = false;
    } else {
      this.calendarButtonTarget.classList.add("selected");
      this.calendarInstance.open();
      this.calendarOpen = true;
    }
  }

  async refreshCalendarSettings() {
    if (!this.calendarInstance) {
      const Flatpickr = await loadFlatpickr();

      this.calendarInstance = Flatpickr(this.calendarButtonTarget, {
        position: "auto left",
        mode: "range",
        onChange: this.handleCalendarChange.bind(this),
        onClose: () => {
          this.calendarOpen = false;
          this.calendarButtonTarget.classList.remove("selected");
        }
      });
    }

    const mainDataAxis = this.chartInstance.series[0].xAxis;
    const maxDataAxis = this.chartInstance.navigator?.xAxis || mainDataAxis;
    const { min: selectedRangeFrom, max: selectedRangeTo } = mainDataAxis;
    const { min: allowedRangeFrom, max: allowedRangeTo } = maxDataAxis;

    this.calendarInstance.config.minDate = allowedRangeFrom;
    this.calendarInstance.config.maxDate = allowedRangeTo;
    this.calendarInstance.setDate([selectedRangeFrom, selectedRangeTo], false);
  }


  refreshChartSettings(isAxisChange = false) {
    if (this.hasTradingViewChartContainerTarget) {
      this.tradingViewChartContainerTarget.classList.add("tw-hidden");
    }

    this.chartTypeSelectorTarget.classList.remove("tw-hidden");
    this.rangeSelectorTarget.classList.remove("tw-hidden");
    this.normalChartTarget.classList.remove("tw-hidden");

    const isMarketCap = this.selectedChart === "market_cap";
    const isOhlc = this.selectedChart === "ohlc";

    this.lineButtonTarget.classList.toggle("selected", !isOhlc);
    this.ohlcButtonTarget.classList.toggle("selected", isOhlc);
    this.ohlcButtonTarget.classList.toggle("!tw-hidden", isMarketCap);

    this.chartTarget.dataset.ohlcChart = isOhlc;
    this.chartTarget.dataset.dataLabel = isMarketCap ? I18n.t("charts.market_cap") : I18n.t("charts.price");
    this.chartTarget.dataset.showBtc = this.isBtcEnabled;
    this.chartTarget.dataset.showEth = this.isEthEnabled;

    // Axis change won't need data URL update; we are showing same data in different currencies.
    if (!isAxisChange) {
      this.chartTarget.dataset.dataUrl = this.chartUrls?.[this.selectedChart]?.[this.selectedRange];
      this.chartTarget.dataset.customDataUrl = this.chartUrls?.[this.selectedChart]?.["custom"] || this.chartTarget.dataset.customDataUrl;
    }

    this.chartTarget.dataset.priceChartRerenderValue = true;
  }


  onChartRerender(e) {
    this.chartInstance = e.detail.chart;

    if (e.detail.type === "custom") {
      this.selectedRange = "custom";
      this.rangeSelectorTarget.querySelector("[id^='range'].selected")?.classList?.remove("selected");
    }

    this.refreshCalendarSettings();
  }
}
