import { Controller } from "stimulus";
import { loadFlatpickr } from "../../../util/load_package";
import { getActiveCurrency } from "../../../util/currency";
import { getCSRFToken, setUpdatedCsrfMetaContent } from "../../../util";
import { EventSelectPortfolio } from "../../../events";
import { hideFormInputError, showFormInputError } from "../../../util/gecko_primer";

const DISABLE_COIN_INPUT_PAGE_TYPES = ["transactions", "coins"];

export default class extends Controller {
  static targets = [
    "transactionType", "coinInput", "dateInput", "feesInput", "notesInput",
    "buyQuantityInput", "buyPriceInput", "buyTotalInput",
    "sellQuantityInput", "sellPriceInput", "sellTotalInput",
    "transferQuantityInput", "transferTypeInput",
    "balance", "coinSymbol", "title", "submit",
    "buyButton", "sellButton", "transferButton",
    "currencyText", "portfolioDropdown",
  ];

  static values = {
    selectedCurrency: String
  }

  portfolioCoinsData = null;

  coinSlug = null;
  coinId = null;
  coinSymbol = null;
  maxQuantity = 0;

  connect() {
    this.portfolioId = +this.element.dataset.portfolioId;
    this.isEdit = false;
    this.selectedCurrencyValue = getActiveCurrency();
    this.disableCoinInput = DISABLE_COIN_INPUT_PAGE_TYPES.includes(this.element.dataset.page);

    this.currencyTextTargets.forEach(target => {
      target.textContent = this.selectedCurrencyValue.toUpperCase()
    });

    this._setupPortfolioChangeListener();
    this._setupCalendar();
  }

  show(e) {
    this.portfolioCoinsData = JSON.parse(this.element.dataset.portfolioCoinsData || "null");
    this._populateFields(e.currentTarget);

    Modal.show("portfolio_transaction_form");
  }

  showCalendar() {
    this.calendarInstance?.open();
  }


  calculateBuy(e) {
    const quantity = +this.buyQuantityInputTarget.value;
    const price = +this.buyPriceInputTarget.value;
    const total = +this.buyTotalInputTarget.value;

    if (quantity <= 0 || (price <= 0 && total <= 0)) {
      return;
    }


    switch (e.currentTarget || e) {
      case this.buyQuantityInputTarget:
      case this.buyTotalInputTarget:
        this.buyPriceInputTarget.value = (total / quantity);
        break;

      case this.buyPriceInputTarget:
        this.buyTotalInputTarget.value = (quantity * price).toFixed(2);
        break;
    }
  }

  calculateSell(e) {
    const quantity = +this.sellQuantityInputTarget.value;
    const price = +this.sellPriceInputTarget.value;
    const total = +this.sellTotalInputTarget.value;

    if (quantity <= 0 || (price <= 0 && total <= 0)) {
      return;
    }


    switch (e.currentTarget || e) {
      case this.sellQuantityInputTarget:
      case this.sellTotalInputTarget:
        this.sellPriceInputTarget.value = (total / quantity);
        break;

      case this.sellPriceInputTarget:
        this.sellTotalInputTarget.value = (quantity * price).toFixed(2);
        break;
    }
  }


  handleCoin() {
    if (!this.portfolioCoinsData) {
      this.portfolioCoinsData = JSON.parse(this.element.dataset.portfolioCoinsData || "null");
    }

    const portfolioCoinId = +this.coinInputTarget.value;
    const portfolioCoinData = this.portfolioCoinsData?.find(data => data.portfolio_coin_id === portfolioCoinId);

    if (portfolioCoinData) {
      this.maxQuantity = portfolioCoinData.total_holdings;
      this.coinSymbol = portfolioCoinData.coin_symbol;
      this.coinSlug = portfolioCoinData.coin_slug;
      this.coinId = portfolioCoinData.coin_id;
    }

    const balance = I18n.t("portfolio.balance", { amount: this.maxQuantity, symbol: this.coinSymbol });
    this.balanceTargets.forEach(target => target.innerText = balance);

    this.coinSymbolTargets.forEach(target => target.innerText = this.coinSymbol);
  }

  handleMax(e) {
    switch (this._getTransactionType()) {
      case "sell":
        this.sellQuantityInputTarget.value = this.maxQuantity;
        this.calculateSell(this.sellQuantityInputTarget);
        break;

      case "transfer":
        this.transferQuantityInputTarget.value = this.maxQuantity;
        break;
    }
  }

  handleUseMarket(e) {
    if (!this.currentPrice) {
      return;
    }

    const convertedPrice = fx(this.currentPrice).from(getActiveCurrency()).to(this.selectedCurrencyValue);
    switch (this._getTransactionType()) {
      case "sell":
        this.sellPriceInputTarget.value = convertedPrice;
        this.calculateSell(this.sellPriceInputTarget);
        break;

      case "buy":
        this.buyPriceInputTarget.value = convertedPrice;
        this.calculateBuy(this.buyPriceInputTarget);
        break;
    }
  }

  async handleSubmit(e) {
    if (!this._validateFields()) {
      return;
    }

    const transaction = {
      fees: +this.feesInputTarget.value,
      transaction_timestamp: this.dateInputTarget.value,
      notes: this.notesInputTarget.value,
      currency: this.selectedCurrencyValue
    };

    switch (this._getTransactionType()) {
      case "buy":
        transaction.quantity = +this.buyQuantityInputTarget.value;
        transaction.price = +this.buyPriceInputTarget.value;
        transaction.transaction_type = "buy";
        break;

      case "sell":
        transaction.quantity = +this.sellQuantityInputTarget.value;
        transaction.price = +this.sellPriceInputTarget.value;
        transaction.transaction_type = "sell";
        break;

      case "transfer":
        transaction.quantity = +this.transferQuantityInputTarget.value;
        transaction.transaction_type = this.transferTypeInputTarget.value;
        break;
    }


    const { url, method } = this._getRequestParameters();
    const data = { portfolio_coin_transaction: { coin_id: this.coinId, ...transaction } };

    const buttonTarget = e.currentTarget;
    buttonTarget.disabled = true;

    await setUpdatedCsrfMetaContent();
    fetch(url, {
      credentials: "same-origin",
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCSRFToken(),
      }
    }).then((response) => response.json())
      .then((data) => {
        if (data.message !== "success") {
          Toaster.toast(I18n.t("metamask.unknown_error"));
          console.error(data);
        } else {
          Toaster.toast(I18n.t("portfolio.transaction_saved"));
          this._trackEvent("add_portfolio_transaction");
          window.location.reload();
        }
      })
      .finally(() => {
        buttonTarget.disabled = false;
      });
  }

  changeCurrency(e) {
    const currencyCode = e?.currentTarget?.getAttribute("data-iso-code") || e;

    this.selectedCurrencyValue = currencyCode

    this.currencyTextTargets.forEach(target => {
      target.textContent = currencyCode.toUpperCase()
    });

    Modal.hide("local_currency_selector");
  }

  handlePortfolioSelect(e) {
    window.dispatchEvent(
      new CustomEvent(EventSelectPortfolio, {
        bubbles: true,
        detail: {
          sourceTarget: e.currentTarget,
        }
      })
    );
  }

  _getRequestParameters() {
    const baseUrl = `/en/portfolios/${this.portfolioId}/${this.coinSlug}/transactions`;

    if (this.isEdit) {
      return { method: "PUT", url: `${baseUrl}/${this.portfolioCoinTransactionId}` }
    } else {
      return { method: "POST", url: baseUrl }
    }
  }

  _getTransactionType() {
    return this.transactionTypeTarget.value;
  }

  async _setupCalendar() {
    if (this.calendarInstance) {
      return;
    }

    const Flatpickr = await loadFlatpickr();
    this.calendarInstance = Flatpickr(this.dateInputTarget, {
      position: "auto left",
      enableTime: true,
      altInput: true,
      altFormat: "d M Y, h:iK",
      dateFormat: "Z",
    });

    this.calendarInstance.calendarContainer.addEventListener("mousedown", e => e.stopImmediatePropagation());
  }

  _populateFields(sourceTarget) {
    const dataset = sourceTarget.dataset;

    this._resetFields();

    this.transferTypeInputTarget.value = "transfer_in";
    this.coinInputTarget.value = +dataset.portfolioCoinId;
    this.coinInputTarget.disabled = this.disableCoinInput;
    this.handleCoin();

    let currency = getActiveCurrency();
    if (dataset.portfolioCoinTransactionData) {
      // If txn data is available, the transaction is being edited.
      const transaction = JSON.parse(dataset.portfolioCoinTransactionData);
      this._populateFieldsForTransaction(transaction);

      currency = transaction.currency || currency;
    }

    this.changeCurrency(currency);
    this._setCurrentPrice(sourceTarget);
  }

  _setCurrentPrice(sourceTarget) {
    let priceTarget = this.disableCoinInput ? document : sourceTarget.closest("tr");

    if (this.element.dataset.page === "coins") {
      const staticCoinPriceElement = priceTarget.querySelector("[data-coin-show-target='staticCoinPrice']");
      priceTarget = staticCoinPriceElement.querySelector("[data-price-target='price']");
    } else {
      priceTarget = priceTarget.querySelector(".gecko-coin-price [data-price-target=\"price\"]")
    }

    this.currentPrice = +priceTarget?.dataset?.prevPrice;
  }

  _populateFieldsForTransaction(transaction) {
    this.isEdit = true;
    this.portfolioCoinTransactionId = transaction.id;
    this.titleTarget.innerText = I18n.t("portfolio.edit_transaction");
    this.submitTarget.innerText = I18n.t("labels.save");

    this.calendarInstance.setDate(transaction.transaction_timestamp);
    this.feesInputTarget.value = transaction.fees;
    this.notesInputTarget.value = transaction.notes;

    switch (transaction.transaction_type) {
      case "buy":
        this.buyQuantityInputTarget.value = transaction.quantity;
        this.buyPriceInputTarget.value = transaction.price;

        this.calculateBuy(this.buyPriceInputTarget);
        this.buyButtonTarget.click();
        break;

      case "sell":
        this.sellQuantityInputTarget.value = transaction.quantity;
        this.sellPriceInputTarget.value = transaction.price;

        this.calculateSell(this.sellPriceInputTarget);
        this.sellButtonTarget.click();
        break;

      case "transfer_in":
      case "transfer_out":
        this.transferQuantityInputTarget.value = transaction.quantity;
        this.transferTypeInputTarget.value = transaction.transaction_type;

        this.transferButtonTarget.click();
        break;
    }
  }

  _resetFields() {
    this.isEdit = false;
    this.portfolioCoinTransactionId = "";
    this.titleTarget.innerText = I18n.t("portfolio.add_transaction");
    this.submitTarget.innerText = I18n.t("portfolio.add_transaction");

    this.calendarInstance.setDate(new Date());
    this.feesInputTarget.value = "";
    this.notesInputTarget.value = "";

    this.buyQuantityInputTarget.value = "";
    this.buyPriceInputTarget.value = "";
    this.buyTotalInputTarget.value = "";
    hideFormInputError(this.buyQuantityInputTarget);
    hideFormInputError(this.buyPriceInputTarget);
    hideFormInputError(this.buyTotalInputTarget);

    this.sellQuantityInputTarget.value = "";
    this.sellPriceInputTarget.value = "";
    this.sellTotalInputTarget.value = "";
    hideFormInputError(this.sellQuantityInputTarget);
    hideFormInputError(this.sellPriceInputTarget);
    hideFormInputError(this.sellTotalInputTarget);

    this.transferQuantityInputTarget.value = "";
    this.transferTypeInputTarget.value = "";
    hideFormInputError(this.transferQuantityInputTarget);
    hideFormInputError(this.transferTypeInputTarget);

    this.buyButtonTarget.click();
  }

  _validateFields() {
    let quantityTarget, priceTarget;
    let formValid = true;

    switch(this._getTransactionType()) {
      case "buy":
        quantityTarget = this.buyQuantityInputTarget;
        priceTarget = this.buyPriceInputTarget;
        break;

      case "sell":
        quantityTarget = this.sellQuantityInputTarget;
        priceTarget = this.sellPriceInputTarget;
        break;

      case "transfer":
        quantityTarget = this.transferQuantityInputTarget;
        priceTarget =  this.transferTypeInputTarget;
        break;
    }

    hideFormInputError(quantityTarget);
    hideFormInputError(priceTarget);

    if (!quantityTarget.value) {
      formValid = false;
      showFormInputError(quantityTarget, I18n.t("validation.cannot_be_empty"));
    }

    if (+quantityTarget.value <= 0) {
      formValid = false;
      showFormInputError(quantityTarget, I18n.t("validation.more_than_zero"));
    }

    if (+priceTarget.value < 0) {
      formValid = false;
      showFormInputError(priceTarget, I18n.t("validation.more_than_zero"));
    }

    return formValid;
  }

  _setupPortfolioChangeListener() {
    if (this.element.dataset.page !== "coins") {
      return;
    }

    window.addEventListener(EventSelectPortfolio, (e) => {
      const { identifier, value } = e.detail.sourceTarget.dataset;
      const selectedPortfolioId = +value;

      if (identifier === "portfolio-transaction-dropdown") {
        if (this.portfolioId === selectedPortfolioId) {
          return;
        }

        this.portfolioId = selectedPortfolioId;
      }
    });
  }

  _trackEvent(eventName) {
    if (this.element.dataset.page !== "coins" || typeof gtag === 'undefined') {
      return;
    }

    gtag("event", eventName, {
      "event_url": window.location.pathname,
    });
  }
}
