import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["d30Toggle", "fdvToggle", "ratioToggle", "table"];

  connect() {
    this.page = this.tableTarget.dataset.page;

    this.d30Enabled = localStorage.getItem(`${this.page}:d30`) == "true";
    this.fdvEnabled = localStorage.getItem(`${this.page}:fdv`) == "true";
    this.ratioEnabled = localStorage.getItem(`${this.page}:ratio`) == "true";

    this._refresh();
  }

  handle30d() {
    this.d30Enabled = !this.d30Enabled;
    localStorage.setItem(`${this.page}:d30`, this.d30Enabled);

    this._refresh();
  }

  handleFdv() {
    this.fdvEnabled = !this.fdvEnabled;
    localStorage.setItem(`${this.page}:fdv`, this.fdvEnabled);

    this._refresh();
  }

  handleRatio() {
    this.ratioEnabled = !this.ratioEnabled;
    localStorage.setItem(`${this.page}:ratio`, this.ratioEnabled);

    this._refresh();
  }

  _refresh() {
    if (this.hasTableTarget) {
      if (this.hasD30ToggleTarget) {
        this.d30ToggleTarget.checked = this.d30Enabled;
        this.tableTarget.querySelectorAll(".d30").forEach(x => x.classList.toggle("tw-hidden", !this.d30Enabled));
      }
      if (this.hasFdvToggleTarget) {
        this.fdvToggleTarget.checked = this.fdvEnabled;
        this.tableTarget.querySelectorAll(".fdv").forEach(x => x.classList.toggle("tw-hidden", !this.fdvEnabled));
      }
      if (this.hasRatioToggleTarget) {
        this.ratioToggleTarget.checked = this.ratioEnabled;
        this.tableTarget.querySelectorAll(".ratio").forEach(x => x.classList.toggle("tw-hidden", !this.ratioEnabled));
      }
    }
  }
}
