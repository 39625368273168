import { formatCurrency } from "../util/currency";

export function convertPrice(price_btc, to_iso_code, raw = false) {
  let converted_price;
  if (to_iso_code === "btc") {
    converted_price = price_btc;
  } else {
    converted_price = fx(price_btc)
      .from("btc")
      .to(to_iso_code);
  }
  return formatCurrency(converted_price, to_iso_code, raw);
}

export function convertPriceFrom(price, from_iso_code, to_iso_code, raw = false) {
  if (from_iso_code === to_iso_code) return price;

  const convertedPrice = fx(price).from(from_iso_code).to(to_iso_code);
  if (raw) return convertedPrice;

  return formatCurrency(convertedPrice, to_iso_code, raw);
}
