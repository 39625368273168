import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["description", "faq"];

  connect() {}

  toggleDescription(e) {
    let enabled = !this.descriptionTarget.classList.contains("!tw-hidden");
    let toggleText = !enabled ? e.currentTarget.dataset.hide : e.currentTarget.dataset.show;

    this.descriptionTarget.classList.toggle("!tw-hidden", enabled);
    e.currentTarget.textContent = toggleText;
  }

  toggleFAQ(e) {
    let enabled = !this.faqTarget.classList.contains("!tw-hidden");
    let toggleText = !enabled ? I18n.t("labels.hide") : I18n.t("labels.show");

    this.faqTarget.classList.toggle("!tw-hidden", enabled);
    e.currentTarget.textContent = toggleText;
  }
}
