import { Controller } from "stimulus";
import { loadFlatpickr } from "../../util/load_package";
import { useClickOutside } from "stimulus-use";
import { createPopper } from "@popperjs/core";

const _1_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export default class extends Controller {
  static targets = ["calendarButton", "exportButton", "exportDropdownMenu", "loadMoreButton", "tableBody"];

  currentPage = 1;
  calendarInstance = null;

  async connect() {
    useClickOutside(this, { element: this.exportButtonTarget });

    if (!this.calendarInstance) {
      const Flatpickr = await loadFlatpickr();
      const defaultDate = JSON.parse(this.element.dataset.defaultCalendarDates);

      this.calendarInstance = Flatpickr(this.calendarButtonTarget, {
        position: "auto left",
        mode: "range",
        defaultDate: defaultDate,
        maxDate: Date.now(),
        onChange: this.handleCalendarChange.bind(this),
        onClose: () => {
          this.calendarOpen = false;
          this.calendarButtonTarget.classList.remove("selected");
        }
      });
    }
  }

  showExportDropdown(e) {
    e.stopPropagation();

    if (this.exportDropdownPopperInstance) {
      return this.hideExportDropdown(e);
    }

    this.exportButtonTarget.classList.add("selected");
    this.exportDropdownMenuTarget.classList.remove("tw-hidden");
    this.exportDropdownPopperInstance = createPopper(this.exportButtonTarget, this.exportDropdownMenuTarget, { placement: "bottom-start" });
  }

  hideExportDropdown(e) {
    if (!this.exportDropdownPopperInstance) {
      return;
    }

    this.exportButtonTarget.classList.remove("selected");
    this.exportDropdownMenuTarget.classList.add("tw-hidden");
    this.exportDropdownPopperInstance?.destroy();
    this.exportDropdownPopperInstance = null;
  }

  toggleCalendar(e) {
    if (this.calendarOpen === true) {
      this.calendarInstance.close();
      this.calendarOpen = false;
    } else {
      this.calendarButtonTarget.classList.add("selected");
      this.calendarInstance.open();
      this.calendarOpen = true;
    }
  }

  handleCalendarChange(selectedDates) {
    // Our Flatpickr is a range selector, so we need to check whether the returned array has two dates (to, from).
    if (!selectedDates || selectedDates.length !== 2) {
      return;
    }

    const [fromDate, toDate] = selectedDates;
    const start = fromDate.toISOString().split('T')[0];
    const end = toDate.toISOString().split('T')[0];
    const params = new URLSearchParams(window.location.search);

    params.set("start", start);
    params.set("end", end);

    window.location.search = params.toString();
  }

  handleOptionChange(e) {
    const key = e.currentTarget.dataset.key;
    const startDate = new Date();

    if (key === "ytd") {
      startDate.setMonth(0, 1)
    } else {
      const days = +key;
      const offset = days * _1_DAY_IN_MILLISECONDS;

      startDate.setTime(startDate.getTime() - offset);
    }

    this.handleCalendarChange([startDate, new Date()]);
  }

  loadMore(e) {
    const maxPages = +e.currentTarget.dataset.maxPages;
    if (this.currentPage >= maxPages) {
      return;
    }

    this.loadMoreButtonTarget.disabled = true;

    let url = new URL(window.location);
    url.searchParams.set("page", this.currentPage + 1);
    url.searchParams.set("load_more", "true");

    fetch(url.toString(), { credentials: "same-origin" })
      .then(response => response.text())
      .then(content => {
        if (!content) {
          return;
        }

        this.tableBodyTarget.insertAdjacentHTML("beforeend", content);

        this.currentPage++;
        if (this.currentPage >= maxPages) {
          this.loadMoreButtonTarget.remove();
        }
      })
      .finally(() => this.loadMoreButtonTarget.disabled = false);
  }
}
